import React from "react"
import Album from "../components/album"
import HahahaCover from "../assets/hahaha-cover.jpg"

export default (props) => {
  const formatsAvailable = "12\" colored vinyl LP, digital download"
  return (
    <Album
      title="Hahaha"
      recordedDate="2016-2017"
      recordedLocation="Allston, MA, and one song in Greenpoint, Brooklyn"
      engineers="Mark Fede (Boston) and Alex Molini (Brooklyn)"
      releaseDate="April 20, 2018"
      recordLabel="Exploding in Sound Records"
      catalogNumber="EIS076"
      formatsAvailable={formatsAvailable}
      location={props.location}
      albumCover={HahahaCover}/>
  )
}
